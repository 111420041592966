<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form method="post" action="javascript:void(0)" ref="summary_gp_total_mark_form" class="repeater-form"
        @submit="showSummaryofGPAndTotal">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Class') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('class')" rules="required">
                <select v-model="classes_id" class="form-control" name="classes_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(cls, key) in classes" :key="key" :value="cls.id">{{ cls.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Version') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('version')" rules="required">
                <select v-model="version_id" class="form-control" name="version_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(version, key) in versions" :key="key" :value="version.id">{{ version.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Shift') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('shift')" rules="required">
                <select v-model="shift_id" class="form-control" name="shift_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(shift, key) in shifts" :key="key" :value="shift.id">{{ shift.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Wing') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('wing')" rules="required">
                <select v-model="wing_id" class="form-control" name="wing_id" :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(wing, key) in wings" :key="key" :value="wing.id">{{ wing.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Student group') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('student group')" rules="required">
                <select v-model="student_group_id" class="form-control" name="student_group_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(sgrp, key) in student_groups" :key="key" :value="sgrp.id">{{ sgrp.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Academic Year') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('academic year')" rules="required">
                <select v-model="academic_year_id" class="form-control" name="academic_year_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(aYear, key) in academic_years" :key="key" :value="aYear.id">{{ aYear.year }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Exam type') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('exam type')" rules="required">
                <select v-model="exam_type_id" class="form-control" name="exam_type_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(examType, key) in active_exam_types" :key="key" :value="examType.id">{{ examType.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" class="mt-2 pt-1">
            <b-button variant="outline-primary" type="submit">
              {{ $t('Find') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx :data="getData" :filename="'filename'" :sheetname="'results'">

        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData" :csv-title="'My_CSV'">
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="downloadSummaryOfGPAndTotalMark()">
        <b>PDF</b>
      </button>
    </div>

    <b-table-simple hover small caption-top responsive>
      <caption>Summary of Grade Point Result</caption>
      <colgroup>
        <col>
        <col>
      </colgroup>
      <colgroup>
        <col>
        <col>
        <col>
      </colgroup>
      <colgroup>
        <col>
        <col>
      </colgroup>
      <b-thead>
        <b-tr>
          <b-th class="text-center" :colspan="1">{{ $t('Pos') }}</b-th>
          <b-th class="text-center" :colspan="1">{{ $t('Cadet No.') }}</b-th>
          <b-th class="text-left" :colspan="1">{{ $t('Name') }}</b-th>
          <b-th class="text-center">{{ $t('Total Mark') }}</b-th>
          <b-th class="text-center">{{ $t('GPA') }}</b-th>
          <b-th class="text-center">{{ $t('Grade') }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(std, key2) in results" :key="key2">
          <b-th class="text-center">{{ key2 + 1 }}</b-th>
          <b-th class="text-center">{{ std.student ? std.student.cadet_no : 'TBD' }}</b-th>
          <b-th class="text-left">{{ std.student ? std.student.userable ? std.student.userable.name : '' : '' }}</b-th>
          <b-td class="text-center">{{ Math.ceil(std.obtained_mark) }}</b-td>
          <b-td class="text-center">{{ getGPA(std.others) }}</b-td>
          <b-td class="text-center">{{ getGradeName(std.others) }}</b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-td colspan="7" class="text-right">
            Total: <b>{{ results.length }}</b>
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BFormGroup,
  BForm, BRow, BCol, BCardText, BTableSimple, BThead, BTr, BTd, BTfoot, BTh, BTbody,
} from 'bootstrap-vue'
import { required } from '@validations'
import download from "downloadjs";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv';
import constants from "@core/utils/constants";
import Vue from "vue";
Vue.use(VueExcelXlsx);
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import { mapGetters } from "vuex";
import moment from "moment"
export default {
  name: 'ResultBySubject',
  components: {
    BCardCode,
    BFormGroup, BThead, BTr, BTd, BTfoot,
    BButton, BCardText, BTh, BTbody,
    BForm, BRow, BCol, BTableSimple,
    ValidationProvider,
    ValidationObserver,
    VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      pageLength: 50,
      searchTerm: '',
      classes_id: null,
      // version_id: null,
      // shift_id: null,
      wing_id: null,
      student_group_id: null,
      academic_year_id: null,
      exam_type_id: null,
      subject_id: null,
      classes: [],
      // versions: [],
      // shifts: [],
      wings: [],
      student_groups: [],
      required,
      dir: false,
      results: [],
      mark_distributions: [],
    }
  },
  methods: {
    showSummaryofGPAndTotal() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data = new FormData(this.$refs.summary_gp_total_mark_form);
          apiCall.post('/result/summary/of/gp/and/total/mark', data).then((response) => {
            this.results = response.data;
          }).catch((error) => {
            if (error.response.status === 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            } else this.$toaster.error(error.response.data.message);
          });
        } else {
          this.results = [];
        }
      });
    },
    
    getGPA(results) {
      let grade_info = this.jsonDecode(results);
      if (grade_info) {
        return (Math.round(grade_info.gpa * 100) / 100).toFixed(2);
      } else {
        return 0;
      }
    },
    getGradeName(results) {
      let grade_info = this.jsonDecode(results);
      if (grade_info) {
        return grade_info.grade_name;
      } else {
        return 'TBD';
      }
    },
    isPass(mk) {
      return mk.length === mk.find(item => item.is_passed === 1);
    },
    /*method for get all classes*/
    async getClasses() {
      await apiCall.get('/get/active/class/with/v/s/sg/wings').then((response) => {
        this.classes = response.data;
      }).catch(() => {
        this.classes = [];
      })
    },
    jsonDecode(data) {
      try {
        return JSON.parse(data);
      }
      catch (e) {
        return [];
      }
    },

    downloadSummaryOfGPAndTotalMark() {
      let data = new FormData(this.$refs.summary_gp_total_mark_form);

      apiCall.post('/generate/summary/of/gp/and/total/mark', data, { responseType: 'blob' }).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'summary_of_gp_total_mark.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['academic_years', 'active_exam_types', 'active_subjects', 'students', 'studentIdByID', 'studentNameByID', 'cadetIdByID']),
    active_exam_types() {
      return this.$store.getters.active_exam_types;
    },
    active_subjects() {
      return this.$store.getters.active_subjects.filter(item => {
        if (item.classes_id === this.classes_id &&
          item.version_id === this.version_id &&
          (item.student_group_id === this.student_group_id || item.student_group_id === parseInt(constants.COMMON_GROUP_ID))) {
          return item;
        }
      });
    },
  },
  created() {
    this.getClasses();
    if (this.active_exam_types.length < 1) this.$store.dispatch('GET_ACTIVE_EXAM_TYPE');
    if (this.academic_years.length < 1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if (this.active_subjects.length < 1) this.$store.dispatch('GET_ACTIVE_SUBJECT');
    if (this.students.length < 1) this.$store.dispatch('GET_ALL_STUDENT');
  },
  watch: {
    classes_id() {
      if (this.classes_id) {
        this.versions = [];
        this.shifts = [];
        this.wings = [];
        this.student_groups = [];
        this.version_id = null;
        this.shift_id = null;
        this.wing_id = null;
        this.student_group_id = null;
        let cls = this.classes.find(item => item.id === this.classes_id);
        if (cls) {
          this.versions = cls.versions;
          this.shifts = cls.shifts;
          this.wings = cls.wings;
          this.student_groups = cls.student_groups;
        }
      } else {
        this.versions = [];
        this.version_id = null;
        this.shifts = [];
        this.shift_id = null;
        this.wings = [];
        this.wing_id = null;
        this.student_group_id = null;
        this.student_groups = [];
      }
    },
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>